* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

@media (min-height: 450px) {
  .theme-provider {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.content-container {
  padding: 8px 20px 0;
}

.table-container {
  position: relative;
}

h1, h2 {
  margin: 0;
}