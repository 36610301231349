@media (max-width: 560px) {
  .app-bar-links-container > *:not(:last-child) {
    display: none;
  }
}

@media (max-width: 700px) {
  .app-bar-panels-item {
    display: none;
  }
}

@media (min-width: 701px) {
  .app-bar-panels-overflow-set {
    display: none;
  }
}