@media (min-width: 520px) {
  .pagination-btn-small-screen {
    display: none;
  }
}

@media (max-width: 521px) {
  .pagination-btn-large-screen {
    display: none;
  }
}