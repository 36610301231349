.ms-ProgressIndicator-itemName {
  overflow: auto;
  white-space: normal;
}

@media (max-width: 820px) {
  .ms-ProgressIndicator {
    width: auto;
    margin-right: 10px;
  }
}